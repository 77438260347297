import { Button, Heading, Icon, Paragraph } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { URLQueryParams } from '@Core/url/URLQueryParams';
import { usePathTemplate } from '@Core/usePathTemplate';

interface LoginToUseFavouritesProps {
  masterId?: number;
  title: string;
  description: string;
}

export const LoginToUseFavourites: React.FC<LoginToUseFavouritesProps> = ({
  masterId,
  title,
  description,
}) => {
  const loginPath = usePathTemplate('customerlogin');
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { pathname, search, hash } = useLocation();
  const queryParams = new URLQueryParams({
    source: 'favourites',
    continuePath: `${pathname}${search}${hash}`,
  });
  if (masterId) {
    queryParams.set('masterId', masterId.toString());
  }

  const href = `${loginPath}?${queryParams}`;

  return (
    <div sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Icon
        name="Content/USPFavourites"
        size="48"
      />

      <Heading
        variant="largebold"
        as="h2"
        sx={{ marginTop: 's' }}
      >
        {title}
      </Heading>

      <Paragraph
        variant="small"
        sx={{ paddingX: 's', paddingY: '3xs', textAlign: 'center' }}
      >
        {description}
      </Paragraph>

      <Button
        variant="PrimaryLegacy"
        size="48"
        sx={{ marginTop: 'xs' }}
        href={href}
        loading={loading}
        onClick={() => {
          setLoading(true);
        }}
      >
        {t('favourites.loginToUseFavourites.cta')}
      </Button>
    </div>
  );
};
